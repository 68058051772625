import { ChainId, JSBI, Percent, Token } from '@pancakeswap/sdk'
import { parseUnits } from '@ethersproject/units'
import { FacebookIcon, LanguageIcon, TelegramIcon, TwitterIcon, YoutubeIcon } from '@pancakeswap/uikit'
import { BigNumber } from '@ethersproject/bignumber'
import { MAIN_TOKEN2, BUSD, MAIN_TOKEN3, MAIN_TOKEN, MAIN_TOKEN4, USDT, WBNB } from './tokens'
import { ChainTokenList } from './types'


export const ROUTER_ADDRESS = {
  [ChainId.BSC]: '0xeb806AF2c5f365a47884306e7C12dBf618eF9685',
  [ChainId.BSC_TESTNET]: '0x91E8AB60a6e836cC0F76d2Cce5DC3B214d111543',
}
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.BSC]: [MAIN_TOKEN[ChainId.BSC], USDT[ChainId.BSC], BUSD[ChainId.BSC], WBNB[ChainId.BSC], MAIN_TOKEN2[ChainId.BSC], MAIN_TOKEN3[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [MAIN_TOKEN[ChainId.BSC_TESTNET], USDT[ChainId.BSC_TESTNET], BUSD[ChainId.BSC_TESTNET], WBNB[ChainId.BSC_TESTNET], MAIN_TOKEN2[ChainId.BSC_TESTNET], MAIN_TOKEN3[ChainId.BSC_TESTNET], MAIN_TOKEN4[ChainId.BSC_TESTNET]],
}

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WNATIVE[ChainId.BSC]]
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.BSC]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.BSC]: [MAIN_TOKEN[ChainId.BSC], USDT[ChainId.BSC], WBNB[ChainId.BSC], MAIN_TOKEN2[ChainId.BSC], MAIN_TOKEN3[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [MAIN_TOKEN[ChainId.BSC_TESTNET], USDT[ChainId.BSC_TESTNET], WBNB[ChainId.BSC_TESTNET], MAIN_TOKEN2[ChainId.BSC_TESTNET], MAIN_TOKEN3[ChainId.BSC_TESTNET], MAIN_TOKEN4[ChainId.BSC_TESTNET]],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  [ChainId.BSC]: [MAIN_TOKEN[ChainId.BSC], USDT[ChainId.BSC], WBNB[ChainId.BSC], MAIN_TOKEN2[ChainId.BSC], MAIN_TOKEN3[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
  [ChainId.BSC_TESTNET]: [MAIN_TOKEN[ChainId.BSC_TESTNET], USDT[ChainId.BSC_TESTNET], BUSD[ChainId.BSC_TESTNET], WBNB[ChainId.BSC_TESTNET], MAIN_TOKEN2[ChainId.BSC_TESTNET], MAIN_TOKEN3[ChainId.BSC_TESTNET], MAIN_TOKEN4[ChainId.BSC_TESTNET]],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.BSC]: [
    [MAIN_TOKEN[ChainId.BSC], WBNB[ChainId.BSC]],
    [MAIN_TOKEN[ChainId.BSC], USDT[ChainId.BSC]],
    [MAIN_TOKEN[ChainId.BSC], MAIN_TOKEN2[ChainId.BSC]],
    [MAIN_TOKEN[ChainId.BSC], MAIN_TOKEN3[ChainId.BSC]],
    [MAIN_TOKEN[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
    [MAIN_TOKEN2[ChainId.BSC], WBNB[ChainId.BSC]],
    [MAIN_TOKEN3[ChainId.BSC], WBNB[ChainId.BSC]],
    [MAIN_TOKEN3[ChainId.BSC], MAIN_TOKEN4[ChainId.BSC]],
  ],
}

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_TEN = JSBI.BigInt(10)

// one basis point
export const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(JSBI.BigInt(50), BIPS_BASE)

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')

export const BASE_FEE = new Percent(JSBI.BigInt(25), BIPS_BASE)
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE)

// BNB
export const DEFAULT_INPUT_CURRENCY = {
  [ChainId.BSC]: 'ETH',
  [ChainId.BSC_TESTNET]: 'ETH'
}
// CAKE
export const DEFAULT_OUTPUT_CURRENCY = {
  [ChainId.BSC]: MAIN_TOKEN[ChainId.BSC].address,
  [ChainId.BSC_TESTNET]: MAIN_TOKEN[ChainId.BSC_TESTNET].address,
}

// Handler string is passed to Gelato to use PCS router
export const GELATO_HANDLER = 'pancakeswap'
export const GENERIC_GAS_LIMIT_ORDER_EXECUTION = BigNumber.from(500000)

export const LIMIT_ORDERS_DOCS_URL = 'https://docs.punkswap.exchange/products/pancakeswap-exchange/limit-orders'
export const SUPPORT_CHAINS = [5512, 55]
export const DEFAULT_CHAIN = 5512;

export const blockstarMainnet = {
  id: 5512,
  name: 'Mainnet',
  network: 'blockstar-mainnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BlockStar',
    symbol: 'TBST',
  },
  rpcUrls: {
    public: { http: ['https://mainnet-rpc.blockstar.one'] },
    default: { http: ['https://mainnet-rpc.blockstar.one'] },
  },
  blockExplorers: {
    etherscan: { name: 'BlockStarScan', url: 'https://scan.blockstar.one' },
    default: { name: 'BlockStarScan', url: 'https://scan.blockstar.one' },
  },
  contracts: {
    multicall: {
      address: '0xBEe33b6B1C3df2c4468510E87d6330daA5709F3E',
      blockCreated: 12230,
    },
  },
}

export const blockstarTestnet = {
  id: 55,
  name: 'Testnet',
  network: 'blockstar-test',
  nativeCurrency: {
    decimals: 18,
    name: 'BlockStar',
    symbol: 'TBST',
  },
  rpcUrls: {
    public: { http: ['https://testnet-rpc.blockstar.one'] },
    default: { http: ['https://testnet-rpc.blockstar.one'] },
  },
  blockExplorers: {
    etherscan: { name: 'BlockStarScan', url: 'https://testnet-scan.blockstar.one' },
    default: { name: 'BlockStarScan', url: 'https://testnet-scan.blockstar.one' },
  },
  contracts: {
    multicall: {
      address: '0x3c9d85F5C95E40C52980a8648397ca6E7cfA7932',
      blockCreated: 271099,
    },
  },
}


export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const getChainId = (chain, isPure = false) => {
  if (!isPure && chain && chain.id && SUPPORT_CHAINS.includes(chain.id)) {
    return chain.id;
  }

  if (isPure && chain && SUPPORT_CHAINS.includes(chain)) {
    return chain;
  }
  return DEFAULT_CHAIN;
};


export const socials = [
  {
    label: "BlockStar Website",
    icon: LanguageIcon,
    href: "https://blockstar.site/",
    image : "https://blockstar.site/favicon.ico"
  },
  {
    label: "BlockStar Page",
    icon: LanguageIcon,
    href: "https://blockstar.social/BlockStar_Official",
    image : "http://alchemy.mypinata.cloud/ipfs/bafkreidqhjx2boy3r7uh43uak6y2tbm7biljurra4tga6nch2a72dh7adm"
  },
  {
    label: "BlockStar Group",
    icon: LanguageIcon,
    href: "https://blockstar.social/BlockStar",
    image: "http://alchemy.mypinata.cloud/ipfs/bafkreidqhjx2boy3r7uh43uak6y2tbm7biljurra4tga6nch2a72dh7adm",
  },
  {
    label: "BlockStar Chat",
    icon: LanguageIcon,
    href: "https://blockstar.chat",
    image: "https://blockstar.chat/assets/files/defaults/favicon.png",
  },
  {
    label: "Telegram",
    icon: TelegramIcon,
    href: "https://t.me/BlockStar_Announcements"
  },
  {
    label: "Twitter",
    icon: TwitterIcon,
    href: "https://twitter.com/BlockStar_BST",
  }
];


export const GAS_PRICES = {
  [ChainId.BSC]: {
    "standard": parseUnits('2', 'gwei').toString(),
    "Fast": parseUnits('4', 'gwei').toString(),
    "Instant": parseUnits('5', 'gwei').toString()
  },
  [ChainId.BSC_TESTNET]: {
    "standard": parseUnits('5', 'gwei').toString(),
    "Fast": parseUnits('10', 'gwei').toString(),
    "Instant": parseUnits('15', 'gwei').toString()
  }
}
