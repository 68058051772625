import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'
import { trimAddress } from 'config/constants/exchange'
import { Button, ButtonProps, useMatchBreakpoints } from '@pancakeswap/uikit'

import Trans from './Trans'



const ConnectWalletButton = ({ children, ...props }: ButtonProps) => {
  const { open } = useWeb3Modal()
  const { address } = useAccount()
  const { isMobile, isMd , isTablet } = useMatchBreakpoints();
  const handleClick = () => {
    open()
  }

  return (

    address ? (
      <Button onClick={handleClick} {...props}>
        <div className="" style={{ color: '#000' }}>
          {children || <Trans>{trimAddress(address)}</Trans>}
        </div>
      </Button >
    ) : (
      <Button onClick={handleClick} {...props}>
        <div className="" style={{ color: '#000' }}>
          {children || 
            isMobile ? "Connect" : "Connect Wallet"
          }
        </div>
      </Button>
    )
  )
}

export default ConnectWalletButton
